import { humanize } from "inflection";
import moment from "moment";

import {
  authenticationOfTypeRequired,
  authenticationRequired,
} from "../../shared/routes/Authentication/Login/sagas";
import Patients from "./Patients.js";
import { PatientsReducer } from "./reducer";
import watchPatients from "./sagas";

export default {
  "/patients": {
    component: Patients,
    *onEnter() {
      yield authenticationRequired();
      yield authenticationOfTypeRequired([
        "admin",
        "partner_admin",
        "owner",
        "care_team_user",
        "staff",
      ]);
    },
    reducer: {
      ui: {
        Patients: PatientsReducer,
      },
    },
    saga: watchPatients,
    title: "Patients",
  },
};

const truncate = (longString) => `${longString.substr(0, 27)}...`;

// TODO: this should be moved to reducer.js since it's dependent on the redux-state of searchResults
// this formats certain patient values so that they're presentable for the ui
export const reducePatientDataToTableRow = (patient) =>
  Object.keys(patient).reduce((formattedPatient, key) => {
    switch (key) {
      case "name": {
        let patientName = patient[key];
        if (patientName.length > 30) {
          patientName = truncate(patientName);
        }
        return { ...formattedPatient, name: patientName };
      }
      case "customer": {
        const customer = patient[key] || {};
        let customerName = customer.name || "";
        if (customerName.length > 30) {
          customerName = truncate(customerName);
        }
        return {
          ...formattedPatient,
          customer: { ...customer, name: customerName },
        };
      }
      case "state": {
        const patientStates = patient[key];
        return { ...formattedPatient, state: patientStates };
      }
      case "created_at": {
        const timestamp = patient[key];
        return {
          ...formattedPatient,
          created_at: timestamp && moment(timestamp).format("MM/DD/YYYY"),
        };
      }
      case "archived_at": {
        const timestamp = patient[key];
        return {
          ...formattedPatient,
          archived_at: timestamp && moment(timestamp).format("MM/DD/YYYY"),
        };
      }
      default:
        // default is to simply return the key-value pair
        return { ...formattedPatient, [key]: patient[key] };
    }
  }, {});

// we autosort on a column depending on which filter gets selected. e.g. it makes sense to automatically sort on alerts when someone selects the 'Needs Attention' filter
// hardcoded on the frontend for now
export const filterSortMapping = {
  All: "created_at",
  "All Patients": "created_at",
  "Needs attention": "events.weight",
  Registered: "created_at",
  Archived: "archived_at",
};
