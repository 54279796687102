import { handleActions } from "redux-actions";
import { get } from "lodash";
import { query } from "../../shared/routes/query";

import {
  didFetchResults,
  didFetchExternalFields,
  didFetchGroups,
  didFetchFilters,
  didFetchInvite,
  didFetchCustomers,
  didFetchPartnerUsers,
  resetInviteAdmin,
  setLegacySearch,
} from "./actions";

export function customers(state) {
  const allCustomers = get(state, "ui.Patients.customers");

  if (!allCustomers) return null;

  return allCustomers
    .filter(({ clinicians }) => clinicians.length > 0)
    .sort((a, b) => a.customer.name.localeCompare(b.customer.name));
}

export function invite(state) {
  return get(state, "ui.Patients.invite");
}

export function externalFields(state) {
  return get(state, "ui.Patients.externalFields") || null;
}

export function externalFieldsToSearchOn(state) {
  const fields = externalFields(state);

  if (!fields) return null;

  const defaultFields = ["Name", "Email", "Program"];
  const additionalFields =
    fields &&
    fields.filter((field) => field.for === "patient").map(({ label }) => label);
  // for peerwell admin only, need to de-dup, since docspera ehr and doscpera ehr inpatient return some overlap of external fields
  const uniqueAdditionalFields = [...new Set(additionalFields)];

  return defaultFields.concat(uniqueAdditionalFields);
}

export function groups(state) {
  return get(state, "ui.Patients.groups");
}

export function defaultFilters(state) {
  return get(state, "ui.Patients.defaultFilters");
}

export function searchResults(state) {
  return get(state, "ui.Patients.results.search_results");
}

export function count(state) {
  return get(state, "ui.Patients.results.total");
}

export function allPartnerUsers(state) {
  return get(state, "ui.Patients.partnerUsers");
}

export function customerId(state) {
  return get(state, "userInfo.customer_id") || null;
}

export function patientColumns(state) {
  const filters = defaultFilters(state);
  const selectedFilter = query(state, "events");
  const authenticatedUserType = get(state, "authentication.userType");

  if (!filters) return null;

  const archiveFilter =
    filters && filters.find(({ label }) => label === "Archived");
  const isArchiveViewingMode =
    JSON.stringify(archiveFilter && archiveFilter.filters) === selectedFilter;

  const BASE_COLUMNS = [
    {
      label: "Claim #",
      value: "external_service.data.claim.label",
      sortBy: "external_service.data.claim",
    },
    { label: "Name", value: "name", sortBy: "name" },
    {
      label: "Status",
      value: (p) => {
        const risks = (p.state || []).filter((s) => s?.effect === "negative");
        return risks.length > 0 ? `health risk` : "-";
      },
      sortBy: "events.weight",
      className: "state[0].effect",
    },
    {
      label: "Program",
      value: "formal_program_name",
      sortBy: "formal_program_name",
    },
  ];

  // care team users / viewers don't see these
  // TODO: don't hardcode external fields
  const PRIVILEGED_COLUMNS = [
    {
      label: "Employer",
      value: "external_service.data.employer.label",
      sortBy: "external_service.data.employer",
    },
    {
      label: "Payer",
      value: "external_service.data.payer.label",
      sortBy: "external_service.data.payer",
    },
    { label: "Geography", value: "us_state", sortBy: "us_state" },
  ];

  const PEERWELL_ADMIN_COLUMNS = [
    { label: "Customer", value: "customer.name", sortBy: "customer.id" },
  ];

  let columnsToDisplay = BASE_COLUMNS;

  if (["admin", "partner_admin", "owner"].includes(authenticatedUserType)) {
    columnsToDisplay = [...columnsToDisplay, ...PRIVILEGED_COLUMNS];
  }

  if (authenticatedUserType === "admin") {
    columnsToDisplay = [...columnsToDisplay, ...PEERWELL_ADMIN_COLUMNS];
  }

  if (isArchiveViewingMode) {
    columnsToDisplay.push({
      label: "Date Archived",
      value: "archived_at",
      sortBy: "archived_at",
    });
  } else {
    columnsToDisplay.push({
      label: "Date Added",
      value: "created_at",
      sortBy: "created_at",
    });
  }

  return columnsToDisplay;
}

export function shouldLegacySearch(state) {
  console.log(state);
  return get(state, "ui.Patients.legacySearch");
}

export const PatientsReducer = handleActions(
  {
    [setLegacySearch]: (state, action) => ({
      ...state,
      legacySearch: action.payload,
    }),
    [didFetchResults]: (state, action) => ({
      ...state,
      results: action.payload,
    }),
    [didFetchExternalFields]: (state, action) => ({
      ...state,
      externalFields: action.payload,
    }),
    [didFetchGroups]: (state, action) => ({ ...state, groups: action.payload }),
    [didFetchFilters]: (state, action) => ({
      ...state,
      defaultFilters: action.payload,
    }),
    [didFetchInvite]: (state, action) => ({ ...state, invite: action.payload }),
    [didFetchCustomers]: (state, action) => ({
      ...state,
      customers: action.payload,
    }),
    [didFetchPartnerUsers]: (state, action) => ({
      ...state,
      partnerUsers: action.payload,
    }),
    [resetInviteAdmin]: (state, action) => ({
      ...state,
      invite: null,
      partnerUsers: null,
    }),
  },
  {
    results: null,
    externalFields: null,
    groups: null,
    defaultFilters: null,
    invite: null,
    customers: null,
    partnerUsers: null,
    legacySearch: false,
  }
);
