import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import { isEqual, get } from "lodash";

import { Link } from "redux-little-router";
import LoadingIndicator from "../../shared/components/LoadingIndicator";
import Pagination from "../../shared/components/Pagination";
import UserSpecific from "../../shared/components/UserSpecific";
import BreadCrumbBar from "../../components/BreadCrumbBar/BreadCrumbBar";
import ModalPanel from "../../shared/components/ModalPanel";
import KeywordSearch from "./components/KeywordSearch";
import PatientInviteForm from "./components/PatientInviteForm";
import GroupDropdown from "./components/GroupDropdown";
import KeywordSearchHelp from "../../assets/keyword_search_help.svg";

import { reducePatientDataToTableRow, filterSortMapping } from "./";

import "./Patients.css";
import { Archive } from "./components/GroupDropdown/Archive";

const PER_PAGE = 30;

// this is so that the "All" tab is auto-selected when the user first visits the page, it's hardcoded to the API's definition of what 'All' is.
export const ALL_PATIENTS_FILTER = JSON.stringify([
  { type: "inactive", action: "exclude" },
]);

class Patients extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      clickedPatient: null,
      keywordSearchActive: false,
      lastKeywordSearch: "",
    };
    // for auto-scrolling to the top of the search results table on page change
    this.tableRef = null;

    this.handleGroupChange = this.handleGroupChange.bind(this);
    this.activateKeywordSearch = this.activateKeywordSearch.bind(this);
    this.renderGroup = this.renderGroup.bind(this);
    this.renderFilter = this.renderFilter.bind(this);
    this.renderColumnHeader = this.renderColumnHeader.bind(this);
    this.renderRow = this.renderRow.bind(this);
  }

  componentDidMount() {
    const {
      requestGroups,
      groups,
      requestFilters,
      requestExternalFields,
      externalFields,
      requestInvite,
      invite,
      requestPartnerUsers,
      userType,
      customerId,
      searchResults,
      count,
      groupType,
      groupValue,
      keyword,
      noQuery,
      onQueryChange,
      shouldLegacySearch,
    } = this.props;
    const { clickedPatient } = this.state;

    // this preload assets for more fluid ux
    new Image().src = KeywordSearchHelp;

    const canRequestGroups = ["admin", "partner_admin"].includes(userType);
    const canRequestInvite = ["partner_admin", "owner"].includes(userType);

    // if these are already fetched, we don't need to refresh them anytime soon
    if (!groups && canRequestGroups) requestGroups(shouldLegacySearch);
    if (!invite && canRequestInvite) requestInvite();
    if (!externalFields) requestExternalFields();

    if (["admin", "partner_admin"].includes(userType)) {
      requestFilters(groupType, groupValue);
    } else {
      requestFilters();
    }
    // for some reason, userInfo.customerId in redux-state does not resolve by the time this component mounts, so this call doesn't do anything I don't think, although it should
    // TODO: figure out why
    if (customerId) {
      requestPartnerUsers(customerId);
    }

    // initialize the default search query if no query string in url, else, a query is already in the url and user is refreshing
    if (noQuery) {
      onQueryChange({
        groupType: "all",
        events: ALL_PATIENTS_FILTER,
        sortBy: "created_at",
      });
    } else {
      this.search(this.props);
    }

    // if user refreshes page with some some query params already in the url, results will get shown so we need to have the top result auto-clicked to keep consistent ux behavior
    if (!clickedPatient && searchResults && count > 0) {
      const firstResult = searchResults[0];
      this.setState({ clickedPatient: firstResult.id });
    }

    // if user refreshes page with 'keyword' query param passed then this initializes the local state as needed
    if (keyword) {
      this.setState({ lastKeywordSearch: keyword, keywordSearchActive: true });
    }
  }

  componentDidUpdate(prevProps) {
    const {
      requestPartnerUsers,
      requestFilters,
      groupType,
      groupValue,
      filter,
      keyword,
      sortBy,
      sortDirection,
      page,
      searchResults,
      count,
      customerId,
      shouldLegacySearch,
      requestGroups,
      userType,
    } = this.props;

    if (
      groupType !== prevProps.groupType ||
      groupValue !== prevProps.groupValue ||
      filter !== prevProps.filter ||
      keyword != prevProps.keyword ||
      sortBy !== prevProps.sortBy ||
      sortDirection !== prevProps.sortDirection ||
      page !== prevProps.page ||
      shouldLegacySearch !== prevProps.shouldLegacySearch
    ) {
      this.search(this.props);
    }
    if (
      groupType !== prevProps.groupType ||
      groupValue !== prevProps.groupValue ||
      shouldLegacySearch !== prevProps.shouldLegacySearch
    ) {
      requestFilters(groupType, groupValue, shouldLegacySearch);
    }

    const canRequestGroups = ["admin", "partner_admin"].includes(userType);
    if (
      canRequestGroups &&
      shouldLegacySearch !== prevProps.shouldLegacySearch
    ) {
      requestGroups(shouldLegacySearch);
    }

    // for some reason, userInfo.customerId in redux-state does not resolve by the time this component mounts, so this call only works here right now, but should work in componentDidMount
    // TODO: figure out why
    if (customerId && customerId !== prevProps.customerId) {
      requestPartnerUsers(customerId);
    }

    if (!isEqual(searchResults, prevProps.searchResults) && count > 0) {
      this.tableRef.scrollTop = 0;
      const firstResult = searchResults[0];
      if (firstResult) {
        this.setState({ clickedPatient: firstResult.id });
      }
    }
  }

  search({
    requestResults,
    groupType,
    groupValue,
    filter,
    keyword,
    sortBy,
    sortDirection,
    page,
    shouldLegacySearch,
  }) {
    requestResults(
      groupType,
      groupValue,
      filter,
      keyword,
      sortBy,
      sortDirection,
      page,
      shouldLegacySearch
    );
  }

  handleGroupChange(groupType, groupValue) {
    const { onQueryChange, filter } = this.props;

    this.setState({ keywordSearchActive: false });
    onQueryChange({
      groupType,
      groupValue,
      keyword: undefined,
      events: filter || ALL_PATIENTS_FILTER,
      page: 0,
    });
  }

  handleFilterChange(filters, filterLabel) {
    const { onQueryChange } = this.props;

    this.setState({ keywordSearchActive: false });
    onQueryChange({
      events: JSON.stringify(filters),
      sortBy: filterSortMapping[filterLabel],
      keyword: undefined,
      page: 0,
    });
  }

  activateKeywordSearch(searchTerm) {
    const { onQueryChange } = this.props;
    searchTerm = searchTerm.trim();

    this.setState({ keywordSearchActive: true, lastKeywordSearch: searchTerm });
    if (searchTerm) {
      onQueryChange({
        keyword: searchTerm,
        groupType: undefined,
        groupValue: undefined,
        events: undefined,
        sortBy: "created_at",
        sortDirection: "desc",
        page: 0,
      });
    }
  }

  handleSort(nextSortBy) {
    const { onSortChange, sortBy, sortDirection } = this.props;
    const nextSortDirection =
      sortDirection === "asc" && nextSortBy === sortBy ? "desc" : "asc";

    onSortChange(nextSortBy, nextSortDirection);
  }

  handleClickPatientRow(patient) {
    this.setState({ clickedPatient: patient.id });
  }

  renderKeywordSearchStatus() {
    const { keyword, count, isLoading, userType } = this.props;
    const { keywordSearchActive, lastKeywordSearch } = this.state;

    let searchStatusText;
    if (isLoading) {
      searchStatusText = "Running search...";
    } else if (lastKeywordSearch) {
      searchStatusText = `Results for: "${keyword}"`;
    } else {
      searchStatusText = "Run a search";
    }

    let keywordSearchStatus = null;
    if (["admin", "partner_admin"].includes(userType)) {
      keywordSearchStatus = (
        <div key="Keyword Search Status" className="filter selectedFilter">
          <div className="filter-text-group">
            <span className="filter-label">{searchStatusText}</span>
            {lastKeywordSearch && !isLoading && (
              <span className="filter-number">{count}</span>
            )}
          </div>
        </div>
      );
    } else if (
      ["owner", "care_team_user"].includes(userType) &&
      keywordSearchActive
    ) {
      keywordSearchStatus = (
        <div className="search-status">
          <span className="search-status-text">{searchStatusText}</span>
          {lastKeywordSearch && !isLoading && (
            <span className="search-status-number">{count}</span>
          )}
        </div>
      );
    }

    return keywordSearchStatus;
  }

  renderKeywordSearchHelp() {
    return (
      <div className="keyword-search-help-container">
        <div className="keyword-search-help">
          <img src={KeywordSearchHelp} alt="Keyword" />
          <div className="keyword-search-text-content">
            <p className="main-text">
              This is where you can see search results
            </p>
            <p className="supporting-text">
              Search for patients in the box above by their name, claim, email
              or employer
            </p>
          </div>
        </div>
      </div>
    );
  }

  renderNoResults() {
    const { fieldsToSearchOn, searchResults, isLoading, count } = this.props;

    const hasResults = searchResults && count > 0;

    if (!fieldsToSearchOn || hasResults || isLoading) return null;

    return (
      <div className="no-results-container">
        <div className="no-results">
          <h1 className="no-results-found-header">No Results Found</h1>
          <p className="you-can-search-on">You can search on:</p>

          <div className="fields-to-search-on">
            {fieldsToSearchOn.map((field) => (
              <p key={field} className="field-to-search-on">{`- ${field}`}</p>
            ))}
          </div>
        </div>
      </div>
    );
  }

  renderGroup(group) {
    const { groupType, groupValue } = this.props;

    if (group.values) {
      return (
        <GroupDropdown
          options={group.values}
          groupType={group.type}
          groupValue={groupValue}
          groupLabel={group.label}
          onGroupChange={this.handleGroupChange}
        />
      );
    }
    const groupClassName = classNames("group-filter", {
      selectedGroupFilter:
        group.type === groupType && !this.state.keywordSearchActive,
    });

    return (
      <li
        key={group.type}
        role="button"
        className={groupClassName}
        onClick={() => this.handleGroupChange(group.type)}
      >
        <span className="group-filter-label">{group.label}</span>
      </li>
    );
  }

  renderGroups() {
    const { groups, userType } = this.props;
    const { keywordSearchActive, lastKeywordSearch } = this.state;

    if (!groups) return null;

    return [
      ...groups.map(this.renderGroup),
      <KeywordSearch
        keywordSearchActive={keywordSearchActive}
        lastKeywordSearch={lastKeywordSearch}
        userType={userType}
        activateKeywordSearch={this.activateKeywordSearch}
      />,
    ];
  }

  renderAddPatient() {
    const { showPatientInviteForm, isLoading, userType } = this.props;

    const patientInvite = (
      <ModalPanel
        name="patientInvite"
        backgroundColor="#FAFAFA"
        borderRadius="15px"
        width="844px"
      >
        <PatientInviteForm isAdmin={userType === "admin"} />
      </ModalPanel>
    );

    return (
      <div className="partner-admin-row-right">
        <button
          className={classNames("add-patient-button", {
            addPatientButtonAsOwner: userType === "owner",
          })}
          onClick={showPatientInviteForm}
          disabled={isLoading}
        >
          Add Patient
        </button>
        {patientInvite}
      </div>
    );
  }

  renderFilter(filter) {
    const { userType } = this.props;
    const { keywordSearchActive } = this.state;

    const keywordSearchActiveForNonAdmin =
      ["owner", "care_team_user"].includes(userType) && keywordSearchActive;

    // we renamed it 'events' but this is equivalent to filters
    const selectedFilter = this.props.filter;
    const { filters, label, count, important } = filter;

    const filterClassName = classNames("filter", {
      selectedFilter:
        JSON.stringify(filters) === selectedFilter &&
        !keywordSearchActiveForNonAdmin,
    });
    const filterNumberClassName = classNames("filter-number", {
      filterNumberImportant: important,
    });

    return (
      <div
        key={label}
        role="button"
        className={filterClassName}
        onClick={() => {
          this.handleFilterChange(filters, label);
        }}
      >
        <div className="filter-text-group">
          <span className="filter-label">{label}</span>
          <span className={filterNumberClassName}>{count}</span>
        </div>
      </div>
    );
  }

  renderFilters() {
    const { defaultFilters, userType } = this.props;
    const { keywordSearchActive, lastKeywordSearch } = this.state;

    if (!defaultFilters) return null;

    let content;
    if (keywordSearchActive && ["partner_admin", "admin"].includes(userType)) {
      content = this.renderKeywordSearchStatus();
    } else {
      content = [
        ...defaultFilters.map(this.renderFilter),
        <UserSpecific
          key="non-admin-keyword-search"
          className="non-admin-keyword-search"
          type={["owner", "care_team_user"]}
        >
          <KeywordSearch
            keywordSearchActive={keywordSearchActive}
            lastKeywordSearch={lastKeywordSearch}
            userType={userType}
            activateKeywordSearch={this.activateKeywordSearch}
          />
        </UserSpecific>,
      ];
    }

    return (
      <div className="filters-container">
        <div className="filters">{content}</div>
        <UserSpecific type={["owner"]}>{this.renderAddPatient()}</UserSpecific>
      </div>
    );
  }

  renderColumnHeader(header) {
    const { sortBy, sortDirection } = this.props;

    let arrow = null;
    if (header.sortBy === sortBy) {
      const arrowClassName =
        sortDirection === "asc" ? "caret-up" : "caret-down";
      arrow = <span className={classNames(arrowClassName, "caret")} />;
    }

    return (
      <th key={header.value} onClick={() => this.handleSort(header.sortBy)}>
        <span>{header.label}</span>
        {arrow}
      </th>
    );
  }

  renderColumnHeaders() {
    const { columns, count } = this.props;

    const columnHeaders =
      columns && columns.map(this.renderColumnHeader).concat(<th>Archived</th>);

    return (
      <thead className={classNames({ hideTableHead: count === 0 })}>
        <tr>{columnHeaders}</tr>
      </thead>
    );
  }

  renderRow(patient) {
    const { columns, goToPatientDetails } = this.props;
    const { clickedPatient } = this.state;

    const { type, id } = patient;
    const href = `/${type}s/view/${id}`;

    const rowClassName = classNames({ clickedRow: id === clickedPatient });

    const patientRow =
      columns &&
      columns.map((column) => {
        const content =
          typeof column.value === "string"
            ? get(patient, column.value)
            : column.value(patient);
        const cellClassName = get(patient, column.className) || "";

        return (
          <td key={column.value} className={cellClassName}>
            {content || <span>&nbsp;&nbsp;&nbsp;--</span>}
          </td>
        );
      });

    const archiveCheckbox = (
      <td key="archived" className="archived-container">
        <Archive patient={patient} isInvite={type === "invite"} />
      </td>
    );

    const patientProfileLink = (
      <td key={href} className="td-with-button">
        <Link href={href}>
          <button className="view-patient-details-button">View Details</button>
        </Link>
      </td>
    );

    const rowContent = patientRow
      ? [...patientRow, archiveCheckbox, patientProfileLink]
      : [];

    return (
      <tr
        key={id}
        className={rowClassName}
        onClick={() => this.handleClickPatientRow(patient)}
        onDoubleClick={() => goToPatientDetails(href)}
      >
        {rowContent}
      </tr>
    );
  }

  renderRows() {
    const { searchResults, isLoading } = this.props;

    if (!searchResults) return null;

    const formattedPatients = searchResults.map(reducePatientDataToTableRow);
    const patientRows = formattedPatients.map(this.renderRow);

    return (
      <tbody className={classNames({ hideTableRows: isLoading })}>
        {patientRows}
      </tbody>
    );
  }

  renderSearchResults() {
    const { keywordSearchActive, lastKeywordSearch } = this.state;
    // this means the search tab was clicked but a search hasn't been run yet -- helps user know what to do
    if (keywordSearchActive && !lastKeywordSearch) {
      return this.renderKeywordSearchHelp();
    }

    return (
      <div>
        <UserSpecific type={["owner", "care_team_user"]}>
          {this.renderKeywordSearchStatus()}
        </UserSpecific>
        <div
          className="patient-table-container"
          ref={(node) => {
            this.tableRef = node;
          }}
        >
          <table className="patient-table">
            {this.renderColumnHeaders()}
            {this.renderRows()}
          </table>
          {this.renderNoResults()}
          <LoadingIndicator className="loading-indicator" />
          {this.renderPagination()}
        </div>
      </div>
    );
  }

  renderPagination() {
    const { page, count, isLoading } = this.props;

    if (count == null || count <= PER_PAGE || isLoading) return null;

    return (
      <Pagination
        itemCount={count}
        itemsPerPage={PER_PAGE}
        baseUrl={"/patients"}
        currentPage={page}
        pageInQuery
      />
    );
  }

  renderAdminHeader() {
    return (
      <UserSpecific type={["admin", "partner_admin"]}>
        <BreadCrumbBar>
          <div className="partner-admin-row">
            <div className="partner-admin-row-left">{this.renderGroups()}</div>
            {this.renderAddPatient()}
          </div>
        </BreadCrumbBar>
        {this.renderFilters()}
      </UserSpecific>
    );
  }

  renderStaffHeader() {
    return (
      <UserSpecific type={["owner", "care_team_user"]}>
        <BreadCrumbBar>{this.renderFilters()}</BreadCrumbBar>
      </UserSpecific>
    );
  }

  render() {
    return (
      <div className="patients">
        {/* Header for Admin or Partner Admin */}
        {this.renderAdminHeader()}
        {/* Header for Owner or Care Team User */}
        {this.renderStaffHeader()}

        {this.renderSearchResults()}
      </div>
    );
  }
}

Patients.propTypes = {
  onQueryChange: PropTypes.func.isRequired,
  events: PropTypes.array,
  keyword: PropTypes.string,
  sortBy: PropTypes.string,
  sortDirection: PropTypes.string,
  page: PropTypes.number,
};

export default Patients;
