import { createAction } from "redux-actions";
import { all, call, put, takeLatest } from "redux-saga/effects";
import { handleApiError } from "../../../../shared/app/requestErrorHandler";
import { callAPI } from "../../../../shared/utils/sagas";
// # Todo: shouldDisplayLoadingIndicator
import {
  fetchCardInteractions as fetchCardInteractionsAPI,
  fetchCareTeam as fetchCareTeamAPI,
  fetchOwner as fetchOwnerAPI,
  fetchPeer as fetchPeerAPI,
  fetchPeerDeviceInfo as fetchPeerDeviceInfoAPI,
  fetchPeerProfile as fetchPeerProfileAPI,
  fetchPeerProgramParts as fetchPeerProgramPartsAPI,
  fetchWorker as fetchWorkerAPI,
  fetchWorkerEngagement as fetchWorkerEngagementAPI,
} from "./api";
import watchActionsSaga from "./components/Actions/sagas";
import watchFormSaga from "./components/ExternalService/sagas";

const prefixed = (value) => `Peers/View::${value}`;

export const requestPeer = createAction(
  prefixed("REQUEST_PEER"),
  (peerId) => peerId
);
export const didFetchPeer = createAction(prefixed("DID_FETCH_PEER"));

export const requestPeerProfile = createAction(
  prefixed("REQUEST_PEER_PROFILE"),
  (peerId) => peerId
);
export const didFetchPeerProfile = createAction(
  prefixed("DID_FETCH_PEER_PROFILE"),
  (peerId, profile) => ({ id: peerId, profile })
);

export const requestCardInteractions = createAction(
  prefixed("REQUEST_CARD_INTERACTIONS"),
  (peerId) => peerId
);
export const didFetchCardInteractions = createAction(
  prefixed("DID_FETCH_CARD_INTERACTIONS"),
  (peerId, interactions) => ({ id: peerId, interactions })
);

export const requestPeerDeviceInfo = createAction(
  prefixed("REQUEST_PEER_DEVICE_INFO"),
  (peerId) => peerId
);
export const didFetchPeerDeviceInfo = createAction(
  prefixed("DID_FETCH_PEER_DEVICE_INFO")
);

export const requestPeerProgramParts = createAction(
  prefixed("REQUEST_PEER_PROGRAM_PARTS"),
  (peerId) => peerId
);
export const didFetchPeerProgramParts = createAction(
  prefixed("DID_FETCH_PEER_PROGRAM_PARTS")
);

export const requestCareTeam = createAction(
  prefixed("REQUEST_CARE_TEAM"),
  (peerId) => peerId
);
export const didFetchCareTeam = createAction(prefixed("DID_FETCH_CARE_TEAM"));

export const requestOwner = createAction(
  prefixed("REQUEST_OWNER"),
  (peerId) => peerId
);
export const didFetchOwner = createAction(prefixed("DID_FETCH_OWNER"));

function* fetchPeerProfile(action) {
  try {
    const response = yield fetchPeerProfileAPI(action.payload);
    yield put(didFetchPeerProfile(action.payload, response.data));
  } catch (e) {
    handleApiError(e);
  }
}

function* fetchCardInteractions(action) {
  try {
    const response = yield fetchCardInteractionsAPI(action.payload);
    yield put(didFetchCardInteractions(action.payload, response.data));
  } catch (e) {
    // If the card has been deleted, just not show it
    if (e?.response?.data?.error.indexOf("Cannot find") !== -1) {
      return;
    }
    yield handleApiError(e);
  }
}

function* fetchProgramParts(action) {
  try {
    const response = yield fetchPeerProgramPartsAPI(action.payload);
    yield put(
      didFetchPeerProgramParts({ data: response.data, id: action.payload })
    );
  } catch (e) {
    yield handleApiError(e);
  }
}

function* fetchCareTeam(action) {
  try {
    const response = yield fetchCareTeamAPI(action.payload);
    yield put(didFetchCareTeam({ members: response.data, id: action.payload }));
  } catch (e) {
    yield handleApiError(e);
  }
}

function* fetchOwner(action) {
  try {
    const response = yield fetchOwnerAPI(action.payload);
    yield put(didFetchOwner({ owner: response.data, id: action.payload }));
  } catch (e) {
    yield handleApiError(e);
  }
}

function* fetchPeer(action) {
  try {
    const response = yield fetchPeerAPI(action.payload);
    yield put(didFetchPeer(response.data));
  } catch (e) {
    yield handleApiError(e);
  }
}

export const requestWorker = createAction(
  prefixed("REQUEST_WORKER"),
  (workerId) => workerId
);
export const didFetchWorker = createAction(prefixed("DID_FETCH_WORKER"));
function* fetchWorker(action) {
  try {
    const response = yield fetchWorkerAPI(action.payload);
    yield put(didFetchWorker(response.data));
  } catch (e) {
    yield handleApiError(e);
  }
}

export const requestWorkerEngagement = createAction(
  prefixed("REQUEST_WORKER_ENGAGEMENT"),
  (workerId) => workerId
);
export const didFetchWorkerEngagement = createAction(
  prefixed("DID_FETCH_WORKER_ENGAGEMENT")
);
function* fetchWorkerEngagement(action) {
  try {
    const response = yield fetchWorkerEngagementAPI(action.payload);
    yield put(didFetchWorkerEngagement(response.data));
  } catch (e) {
    yield handleApiError(e);
  }
}

export function* watchPeerViewSagas() {
  try {
    yield all([
      watchActionsSaga(),
      watchFormSaga(),
      takeLatest(requestPeer, fetchPeer),
      takeLatest(requestPeerProfile, fetchPeerProfile),
      takeLatest(requestCardInteractions, fetchCardInteractions),
      takeLatest(requestCareTeam, fetchCareTeam),
      takeLatest(requestOwner, fetchOwner),
      takeLatest(requestPeerProgramParts, fetchProgramParts),
      takeLatest(
        requestPeerDeviceInfo,
        callAPI(fetchPeerDeviceInfoAPI, didFetchPeerDeviceInfo)
      ),
      takeLatest(requestWorker, fetchWorker),
      takeLatest(requestWorkerEngagement, fetchWorkerEngagement),
    ]);
  } catch (error) {
    yield handleApiError(error);
  }
}
