function inResettableProgram(patient) {
  const p = patient.program || patient.formal_program_name.toLowerCase();

  const in_ghl_program = p.indexOf("ghl") !== -1;
  const in_blank_ghl_program = p.indexOf("blank") !== -1;

  return in_ghl_program && !in_blank_ghl_program;
}

module.exports = { inResettableProgram };
