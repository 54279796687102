import React from "react";
import "./OverlayAnswer.css";

export function OverlayAnswer({ answer, question }) {
  return (
    <li key={question.text}>
      <div className="assessment-question">
        <p className="assessment-label">{question.text}</p>
        <Question question={question} answer={answer} />
      </div>
    </li>
  );
}

function Question({ question, answer }) {
  if (question.slider) {
    return <Slider question={question} answer={answer} />;
  }

  return <TextInput question={question} answer={answer} />;
}

function Slider({ question, answer }) {
  const { raw } = answer;
  const { max, display_flipped, text_labels } = question.slider;

  const pct = (raw / max) * 100;

  return (
    <div
      className={`question-slider ${display_flipped ? "slider-flipped" : ""}`}
    >
      <div className="slider">
        <div
          title={raw}
          className={`circle circle-${answer.effect || "neutral"}`}
          style={{ marginLeft: `${pct}%` }}
        ></div>
      </div>

      <div className="labels">
        {text_labels.map((n) => (
          <span>{n}</span>
        ))}
      </div>
    </div>
  );
}

function TextInput({ question, answer }) {
  return (
    <p className="textinput" title={answer.raw}>
      {answer.answer}
    </p>
  );
}
