import React from "react";
import PropTypes from "prop-types";

import "./Assessment.css";
import Overlay from "./Overlay";

class Assessment extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      clickedBadge: null,
    };
  }

  renderLine({ label, value, effect }) {
    return (
      <tr key={value} className={`badge-line effect-${effect}`}>
        <td>{label}</td>
        <td>
          <div className="badge-content">{value}</div>
        </td>
      </tr>
    );
  }

  renderLines(lines) {
    return lines.map(this.renderLine);
  }

  renderBadge(badge) {
    return (
      <li
        className="assessment-score"
        onClick={() => this.setState({ clickedBadge: badge })}
        key={badge.title}
      >
        <h2>{badge.title}</h2>

        {badge.score && this.renderScore(badge.score)}

        {badge.lines && (
          <table className="assessment-table table">
            <tbody>{this.renderLines(badge.lines)}</tbody>
          </table>
        )}

        {badge.line && (
          <div className="badge-single-line">{badge.line.label}</div>
        )}

        {this.renderOverlay()}
      </li>
    );
  }

  renderScore({ score, rating, max, effect }) {
    return (
      <p className="badge-score">
        <span className={`effect-${effect}`}>
          <span className="score">{score}</span>
          <span className="max">{max === "%" ? "%" : `/${max}`}</span>
        </span>
        {rating && <span className="rating">({rating})</span>}
      </p>
    );
  }

  render() {
    const { assessment } = this.props;

    if (!assessment.badges) {
      return null;
    }

    return assessment.badges.map((b) => this.renderBadge(b));
  }

  renderOverlay() {
    const { assessment } = this.props;
    const { clickedBadge } = this.state;

    if (clickedBadge) {
      let title = clickedBadge.title;

      if (
        assessment.shortTitle &&
        assessment.shortTitle !== clickedBadge.title
      ) {
        title = `${assessment.shortTitle} ${clickedBadge.title}`;
      }

      return (
        <Overlay
          assessment={assessment}
          onClear={() => this.setState({ clickedBadge: null })}
          relevantQuestions={clickedBadge.relevantQuestions}
          title={title}
        />
      );
    }
    return null;
  }
}

Assessment.propTypes = {
  // Todo: Be more specific
  assessment: PropTypes.object.isRequired,
};

export default Assessment;
