import React from "react";
import PropTypes from "prop-types";

import Assessment from "./components/Assessment/Assessment";

import { partition } from "../../../../../../shared/utils/partition";

import "./Assessments.css";

class Assessments extends React.Component {
  renderRow(row, index) {
    return <div className="row" key={index}></div>;
  }

  renderAssessment(assessment) {
    return (
      <ul key={assessment.title}>
        <Assessment assessment={assessment} />
      </ul>
    );
  }

  render() {
    const { assessments } = this.props;

    console.log(assessments);

    const sorted = assessments.sort((a, b) => {
      const sortDiff = b.sortWeight - a.sortWeight;
      if (sortDiff !== 0) {
        return sortDiff;
      }

      const badgeDiff = (b.badges || []).length - (a.badges || []).length;
      if (badgeDiff !== 0) {
        return badgeDiff;
      }

      const at = a.shortTitle || a.title;
      const bt = b.shortTitle || b.title;

      return at.localeCompare(bt);
    });

    return (
      <div className="assessment-scores">
        <h1>Patient reported assessments</h1>

        <ul className="assessment-container">
          {!assessments || assessments.length === 0 ? (
            <p>User has not filled in assessments</p>
          ) : (
            sorted.map((assessment) => this.renderAssessment(assessment))
          )}
        </ul>
      </div>
    );
  }
}

Assessments.propTypes = {
  assessments: PropTypes.array,
};

export default Assessments;
