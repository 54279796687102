import React from "react";
import PropTypes from "prop-types";

import "./Overlay.css";
import { OverlayAnswer } from "./OverlayAnswer";

class Overlay extends React.Component {
  render() {
    const { assessment, onClear, relevantQuestions, title } = this.props;
    const { questions, formattedAnswers, shortTitle } = assessment;

    let sliceOfQuestions = questions;
    let sliceOfAnswers = formattedAnswers;

    if (relevantQuestions) {
      sliceOfQuestions = relevantQuestions.map((i) => questions[i]);
      sliceOfAnswers = relevantQuestions.map((i) => formattedAnswers[i]);
    }

    return (
      <div
        className="assessment-overlay"
        onClick={(event) => {
          onClear();
          return event.stopPropagation();
        }}
      >
        <div className="container" onClick={(e) => e.stopPropagation()}>
          <div className="inner-container">
            <h1>{title || shortTitle}</h1>

            <ul className="questions">
              {sliceOfAnswers.map((a, i) => {
                const q = sliceOfQuestions[i];
                return <OverlayAnswer answer={a} question={q} />;
              })}
            </ul>
          </div>
        </div>
      </div>
    );
  }
}

Overlay.propTypes = {
  // Todo: Be more specific
  assessment: PropTypes.object.isRequired,
  onClear: PropTypes.func.isRequired,
  relevantQuestions: PropTypes.array,
  title: PropTypes.string,
};

export default Overlay;
