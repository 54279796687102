import { connect } from "react-redux";
import {
  corvelLogo,
  genexLogo,
  jwfLogo,
  objectiveSurgicalLogo,
  paradigmLogo,
  sedgwickLogo,
} from "../../assets/partner-logos";
import { hideError } from "../../shared/components/ErrorIndicator/actions";
import { shouldDisplayErrorIndicator } from "../../shared/components/ErrorIndicator/reducer";
import { shouldDisplayLoadingIndicator } from "../../shared/components/LoadingIndicator/reducer";
import {
  isAuthenticated,
  userCustomerId,
  userEmail,
  userFullname,
  userType,
} from "../../shared/routes/Authentication/Login/reducer";
import NavigationBar from "./NavigationBar.jsx";

function partnerLogoSrc(state) {
  const partnerId = userCustomerId(state);

  const partnerToLogoMapping = {
    "5ebc66647ec2aeccc14678d3": corvelLogo,
    "5ea98e1ffa85dda80a432058": genexLogo,
    "5ebc7416ed35642749f23f81": jwfLogo,
    "5ebc71c7ed35642749f23f7f": objectiveSurgicalLogo,
    "5ea9a8a060bd21a8bf27a59b": paradigmLogo,
    "5ae899cd09c94918222d7484": sedgwickLogo,
  };
  return partnerToLogoMapping[partnerId];
}

// Just add here what the pages should be
function pagesForUserType(state) {
  const role = userType(state);

  switch (role) {
    case "clinician":
      return [
        { label: "Reports", path: "/reports" },
        { label: "Manage Cards", path: "/cards" },
      ];
    case "partner_admin":
      return [
        { label: "Patients", path: "/patients" },
        { label: "Team", path: "/team" },
      ];
    case "owner":
      return [{ label: "Patients", path: "/patients" }];
    case "care_team_user":
      return [{ label: "Patients", path: "/patients" }];
    case "prevention:read":
      return [{ label: "Workers", path: "/workers" }];
    default:
      // default is peerwell admin
      return [
        {
          label: "Manage",
          items: [
            { label: "Invite", path: "/invite-portal" },
            { label: "Demo Invite", path: "/invite-portal/?demo=true" },
            { label: "Partner", path: "/manage-partners" },
            { label: "Care Team", path: "/manage-care-team-users" },
            { label: "Cards", path: "/cards" },
            { label: "Modules", path: "/modules" },
            { label: "Phases", path: "/phases" },
            { label: "Programs", path: "/programs" },
          ],
        },
        {
          label: "Reports",
          items: [
            { label: "Core Report", path: "/core-report" },
            { label: "Weekly Adoption", path: "/weekly-adoption-report" },
            {
              label: "Book Of Business",
              path: "/book-of-business/62b34650ecc4be00120b10dc",
            },
            { label: "Billing Report", path: "/billing-report" },
          ],
        },
        { label: "Card Metrics", path: "/cards-metrics" },
        { label: "KPI Stats", path: "/kpi-stats", id: "KPIMenuButton" },
        { label: "Patients", path: "/patients" },
        { label: "Workers", path: "/workers" },
        { label: "Team", path: "/team" },
        { label: "Coaches", path: "/coaches" },
      ];
  }
}

function selectedPage(state) {
  const path = state.router.pathname;
  const pages = pagesForUserType(state);
  for (const menuItem of pages) {
    if (menuItem.path) {
      if (menuItem.path === path) return menuItem.label;
    } else {
      for (const subMenuItem of menuItem.items) {
        if (subMenuItem.path === path) return `${menuItem.label}`;
      }
    }
  }
  return null;
}

// signout is the only account option for now, but Jeff wants this to be extendable since there may be more options in the future
function accountOptionsForUserType(state) {
  const user = userType(state);
  switch (user) {
    default:
      return [{ label: "Signout", path: "/signout", icon: "sign-out" }];
  }
}

function mapStateToProps(state) {
  return {
    partnerLogo: partnerLogoSrc(state),
    externalService: state.authentication.externalService,
    isAuthenticated: isAuthenticated(state),
    userType: userType(state),
    userFullname: userFullname(state),
    userEmail: userEmail(state),
    hasUserInfo: state.userInfo && !!state.userInfo.id,
    accountOptions: accountOptionsForUserType(state),
    pages: pagesForUserType(state),
    selectedPage: selectedPage(state) || "Menu",
    shouldDisplayLoadingIndicator:
      !["/team", "/patients"].includes(state.router.route) &&
      shouldDisplayLoadingIndicator(state),
    shouldDisplayErrorIndicator: shouldDisplayErrorIndicator(state),
  };
}

const dispatch = {
  onHideError: () => hideError(),
};

export default connect(mapStateToProps, dispatch)(NavigationBar);
