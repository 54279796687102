import React from "react";
import PropTypes from "prop-types";

import "./Confirm.css";

function Notify({ message, subText, callback, visible }) {
  if (!visible) {
    return null;
  }

  function handleClick(confirmed) {
    let potentialPromise = callback(confirmed);
    if (potentialPromise?.then) {
      potentialPromise.catch((err) => console.error(err));
    }
  }

  function confirm() {
    handleClick(true);
  }

  return (
    <div className="confirm-outer-container">
      <div className="confirm-container">
        <h4 className="confirm-prompt">{message}</h4>
        <p className="subtext">{subText}</p>

        <form className="form">
          <button
            onClick={confirm}
            type="submit"
            className="btn-lg btn-primary delete-button"
          >
            OK
          </button>
        </form>
      </div>
    </div>
  );
}

Notify.propTypes = {
  message: PropTypes.string.isRequired,
  subText: PropTypes.string,
  callback: PropTypes.func.isRequired,
  visible: PropTypes.bool,
};

export default Notify;
