import React, { useState } from "react";
import axios from "axios";
import Confirm from "../../../../shared/components/Confirm/Confirm";
import Notify from "../../../../shared/components/Confirm/Notify";
import { inResettableProgram } from "./utils";

export function Archive({ patient, isInvite }) {
  const [archived, setArchived] = useState(!!patient.archived_at);
  const [loading, setLoading] = useState(false);
  const [archiveAlertVisible, setArchiveAlertVisible] = useState(false);

  async function updateArchived(newArchived) {
    setLoading(true);
    await axios.post(`/api/admin/v1/search/archive/${patient.id}`, {
      is_archived: newArchived,
    });
    setArchived(newArchived);
    setLoading(false);
    document.location.reload();
  }

  if (loading) {
    return <input type="checkbox" disabled checked={archived} />;
  }

  return (
    <>
      <input
        style={{ cursor: "pointer" }}
        onClick={async () => {
          setArchiveAlertVisible(true);
        }}
        type="checkbox"
        checked={archived}
        className={archived ? "archived-checkbox" : "unarchived-checkbox"}
      />

      {!archived && (
        <Confirm
          callback={(confirmed) => {
            setArchiveAlertVisible(false);

            if (confirmed) {
              return updateArchived(!archived);
            }
          }}
          message={message(patient)}
          subText={subText(patient)}
          visible={archiveAlertVisible}
        />
      )}

      {archived && (
        <Notify
          callback={() => setArchiveAlertVisible(false)}
          message={unarchiveMessage(patient, isInvite)}
          visible={archiveAlertVisible}
        />
      )}
    </>
  );
}

function unarchiveMessage(patient, isInvite) {
  const n = patient.display_name || patient.name;

  if (isInvite) {
    return `Click on "OK" then click the "Start Program" button to unarchive this ${n}.`;
  }

  return `Click on "OK" then click either the "Change Program" or "${
    inResettableProgram(patient) ? "Reset" : "Resume"
  } Program" buttons to unarchive ${n}.`;
}

function message(patient) {
  const n = patient.display_name || patient.name;
  return `Are you sure you want to archive ${n}`;
}

function subText(patient) {
  if (!inResettableProgram(patient)) {
    return;
  }

  return `This patient will reset to program start when unarchived in the future`;
}
