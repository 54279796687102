import React, { useState } from "react";
import PropTypes from "prop-types";
import moment from "moment";
import inflection from "inflection";
import classNames from "classnames";
import Divider from "@material-ui/core/Divider";
import HoverImage from "react-hover-image";
import { Icon } from "react-fa";

import editIcon from "./../../../../../../assets/edit.svg";
import editIconHover from "./../../../../../../assets/edit hover.svg";
import Set from "../../../../../../shared/components/Data/Set/index";
import UserSpecific from "../../../../../../shared/components/UserSpecific";
import ModalPanel from "../../../../../../shared/components/ModalPanel";
import UpdateCareTeamMember from "../../../../Peer/View/components/Actions/components/UpdateCareTeamMember";
import RemoveCareTeamMember from "../../../../Peer/View/components/Actions/components/RemoveCareTeamMember";
import "./Basic.css";
import { availableLanguages } from "../Forms/UpdateLanguage/UpdateLanguage";
import { Archive } from "../../../../../Patients/components/GroupDropdown/Archive";

import { CARE_TEAM_JOB_OPTIONS } from "../Forms/AddCareTeamMember/careTeamJobOptions";
import Confirm from "../../../../../../shared/components/Confirm/Confirm";
import { resetProgram } from "../../../../Peer/api";
import Notify from "../../../../../../shared/components/Confirm/Notify";
import { inResettableProgram } from "../../../../../Patients/components/GroupDropdown/utils";

/* eslint-disable no-mixed-operators */

const dateFormat = "MM/DD/YYYY";

const dividerStyle = {
  marginTop: "0px",
  marginBottom: "20px",
  backgroundColor: "#575D6A",
  boxShadow: "0px 0.5px #b2a9a9",
};
const careMemberDivStyle = { marginBottom: "20px", cursor: "pointer" };
const careMemberStyle = { marginBottom: "0px" };

class Basic extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showDebug: false,
      careTeamHoverKey: null,
      alertMessage: null,
    };

    this.toggleDebug = this.toggleDebug.bind(this);
    this.onHoverShowDelete = this.onHoverShowDelete.bind(this);
    this.onHoverHideDelete = this.onHoverHideDelete.bind(this);
  }

  onHoverShowDelete(key) {
    this.setState({ careTeamHoverKey: key });
  }

  onHoverHideDelete() {
    this.setState({ careTeamHoverKey: null });
  }

  toggleDebug() {
    const { showDebug } = this.state;
    this.setState({ showDebug: !showDebug });
  }

  renderDebugIfNeeded() {
    const { showDebug } = this.state;
    const { user, userType } = this.props;

    if (!showDebug) return null;

    const idLabel = `${inflection.humanize(userType)} ID`;
    return (
      <div className="user-debug-info">
        <Set className="id" label={idLabel} value={user.id} />
      </div>
    );
  }

  renderAdministrativeInfo() {
    const {
      onEditOwner,
      onAddCareTeamMember,
      onRemoveCareTeamMember,
      onRelease,
      user,
      userType,
      careTeam,
      owner,
      onEditCareTeamMember,
    } = this.props; // TODO: fix on edit owner

    const { alertMessage } = this.state;

    const members = careTeam || [];
    const patientOwnerDisplayName = owner && owner.fullname;
    const isInvite = userType === "invite";
    const inviter =
      user.inviter &&
      `${user.inviter.email} (${inflection.humanize(
        user.inviter.userable_type
      )})`;

    return (
      <div>
        <Notify
          callback={() => this.setState({ alertMessage: null })}
          message={alertMessage}
          visible={!!alertMessage}
        />

        <h2 className="patient-info-header">Administrative</h2>
        <Set
          className="owner"
          label="Owner"
          value={patientOwnerDisplayName}
          onEditButton={onEditOwner}
        />
        <table className="careteam-table">
          <tbody>
            {members.map((member, index) => {
              const jobDisplayName =
                CARE_TEAM_JOB_OPTIONS.find((c) => c.value === member.job)
                  ?.label || member.job;
              return (
                <tr
                  key={member.email}
                  onMouseEnter={() => this.onHoverShowDelete(index)}
                  onMouseLeave={this.onHoverHideDelete}
                >
                  <td className="careteam-data">
                    <div
                      className="data-set"
                      style={careMemberDivStyle}
                      onClick={() => onEditCareTeamMember(member.email)}
                    >
                      <h3 className="labels">{jobDisplayName}</h3>{" "}
                      {/* TODO: format this value */}
                      <p style={careMemberStyle}>
                        {member.fullname || member.email}
                      </p>
                    </div>
                  </td>
                  <td>
                    <div>
                      {this.state.careTeamHoverKey === index && (
                        <a
                          role="button"
                          onClick={() => onRemoveCareTeamMember(member.email)}
                          className="close-button"
                        >
                          <Icon name="times" />
                        </a>
                      )}
                    </div>
                  </td>
                  <ModalPanel name={`updateCareTeamMember${member.email}`}>
                    <UpdateCareTeamMember member={member} userId={user.id} />
                  </ModalPanel>
                  <ModalPanel
                    name={`removeCareTeamMember${member.email}`}
                    width="310px"
                  >
                    <RemoveCareTeamMember member={member} userId={user.id} />
                  </ModalPanel>
                </tr>
              );
            })}
          </tbody>
        </table>
        <div className="user-actions">
          <btn className="btn btn-sm" onClick={onAddCareTeamMember}>
            Add care team member
          </btn>
          {!isInvite && (
            <btn className="btn btn-sm" onClick={onRelease}>
              Return to work
            </btn>
          )}
        </div>
        <Set className="invited_by" label="Invited by" value={inviter} />
        <Divider style={dividerStyle} />
      </div>
    );
  }

  renderContactInfo() {
    const { user, onEditEmail, onEditPhone, authenticatedUserType, userType } =
      this.props;
    const canUpdateContactInfo = [
      "admin",
      "partner_admin",
      "owner",
      "staff",
    ].includes(authenticatedUserType);
    return (
      <div>
        <h2 className="patient-info-header">Contact Info</h2>
        <Set
          className="id"
          label={(userType === "invite" && "Invite ID") || "Patient ID"}
          value={user.id}
        />
        <Set
          className="email"
          label="E-mail"
          value={user.about.email}
          onEditButton={canUpdateContactInfo && onEditEmail}
        />
        <Set
          className="phone"
          label="Phone Number"
          value={user.about.phone}
          onEditButton={canUpdateContactInfo && onEditPhone}
        />
        <Set className="state" label="State" value={user.state} />
        <Divider style={dividerStyle} />
      </div>
    );
  }

  renderInsurerInfo() {
    const { user } = this.props;
    return (
      <div>
        <h2 className="patient-info-header">Insurance</h2>
        <Set
          className="insurer"
          label="insurance company"
          value={user.about.insurer || "-"}
        />
        <Set
          className="member-id"
          label="Member ID"
          value={user.about.insurance_member_number || "-"}
        />
        <Set
          className="group-id"
          label="Group ID"
          value={user.about.insurance_group_number || "-"}
        />
        <Divider style={dividerStyle} />
      </div>
    );
  }

  renderExternalServices() {
    const {
      user,
      externalServiceSettings,
      externalServiceComponent,
      authenticatedUserType,
    } = this.props;
    if (
      !externalServiceComponent ||
      !externalServiceSettings ||
      !user.external_service
    ) {
      return null;
    }
    const { external_service, customer } = user;

    return (
      <div>
        {React.createElement(externalServiceComponent, {
          authenticatedUserType: authenticatedUserType,
          data: external_service.data,
          service: customer && customer.name,
          // filters out external service fields like 'group' which belong are on owners/partner admins, not patients
          settings: externalServiceSettings.filter(
            (service) => service.for === "patient"
          ),
          userId: user.id,
        })}
      </div>
    );
  }

  renderDeviceInfo() {
    const { deviceInfo } = this.props;
    return (
      <div>
        <h2 className="patient-info-header">Device Info</h2>
        <Set
          className="device"
          label="OS Name"
          value={deviceInfo && deviceInfo.os_name}
        />
        <Set
          className="device"
          label="OS Version"
          value={deviceInfo && deviceInfo.os_version}
        />
        {deviceInfo &&
          deviceInfo.features &&
          deviceInfo.features.map((feature) => (
            <div>
              <h3>Feature: {feature.feature}</h3>
              <Set
                className="device"
                label="Enabled"
                value={String(feature.enabled)}
              />
              <Set
                className="device"
                label="Supported"
                value={String(feature.supported)}
              />
            </div>
          ))}
        <Divider style={dividerStyle} />
      </div>
    );
  }

  renderMedicalInfo() {
    const {
      user,
      userType,
      authenticatedUserType,
      externalService,
      onEditSurgeryDate,
      onEditSurgeryComplications,
      onEditProcedure,
      onEditBirthDate,
      onChangeLanguage,
    } = this.props;

    const languageLabel = availableLanguages.find(
      (a) => a.value === (user.language || user.about.lang || "en")
    ).label;

    const surgeryDate = user.surgery_date;
    const isInvite = userType === "invite";
    const formattedSurgeryDate =
      surgeryDate && surgeryDate !== 0
        ? moment(surgeryDate).format(dateFormat)
        : null;
    const clinicianName = user.clinician && user.clinician.name;
    const formattedDateBirth =
      user.intake_data &&
      user.intake_data.date_of_birth &&
      moment(user.intake_data.date_of_birth).format(dateFormat);
    const showClinician =
      user.clinician &&
      clinicianName &&
      (authenticatedUserType === "admin" ||
        clinicianName.toLowerCase() !== "john ortho");
    const isAdminOrOwner = ["admin", "partner_admin", "owner"].includes(
      authenticatedUserType
    );
    const canUpdateBirthdate =
      isAdminOrOwner && externalService !== "docspera" && !isInvite;
    const canUpdateProcedureType =
      user.options && user.options.can_change_procedure_types;
    const canUpdateSurgeryDate =
      (user.options && user.options.change_surgery_date) || isAdminOrOwner;
    const canUpdateSurgeryComplications = isAdminOrOwner && !isInvite;
    const showHasComplications =
      !surgeryDate || new Date().getTime() > surgeryDate;
    const showSurgeryDateInDashboard =
      user.options && user.options.show_surgery_date_in_dashboard; // TODO: clarify with API guys if they should send this down for workerscomp
    // this should later be made extensible to a general category of CMS, not just Sedgwick in particular, where a hospital name will not be applicable
    const showHospital = user.customer && user.customer.name !== "Sedgwick CMS";

    return (
      <div>
        <h2 className="patient-info-header">Medical Info</h2>
        <Set
          className="procedure"
          label="Procedure"
          value={inflection.humanize(
            (user.intake_data && user.intake_data.procedure_type) || "-"
          )}
          onEditButton={canUpdateProcedureType ? onEditProcedure : false}
        />
        {showSurgeryDateInDashboard && (
          <Set
            className="surgery-date"
            label="Surgery Date"
            value={formattedSurgeryDate}
            onEditButton={canUpdateSurgeryDate ? onEditSurgeryDate : false}
          />
        )}
        <Set
          className="birthdate"
          label="Birthdate"
          value={formattedDateBirth}
          onEditButton={canUpdateBirthdate && onEditBirthDate}
        />
        {showSurgeryDateInDashboard && showHasComplications && (
          <Set
            className="surgery-complications"
            label="Surgery Complications"
            value={user.has_complications === "true" ? "Yes" : "No"}
            onEditButton={
              canUpdateSurgeryComplications && onEditSurgeryComplications
            }
          />
        )}
        {showClinician && (
          <Set className="clinician" label="Clinician" value={clinicianName} />
        )}
        {showHospital && (
          <Set
            className="hospital"
            label="Hospital"
            value={user.customer.name}
          />
        )}
        <Divider style={dividerStyle} />
        <Set
          label="Language"
          value={languageLabel}
          onEditButton={() => {
            if (!this.ensurePhone()) {
              return;
            }

            if (!this.inResettableProgram(user)) {
              onChangeLanguage();
              return;
            }

            this.setState({ languageAlertVisible: true });
          }}
        />

        <Confirm
          callback={(confirmed) => {
            this.setState({ languageAlertVisible: false });
            if (confirmed) {
              onChangeLanguage();
            }
          }}
          message="Changing patient's language"
          subText="Are you sure? Changing a patients language while not archived resets their program to day 1."
          visible={this.state.languageAlertVisible}
        />
      </div>
    );
  }

  inResettableProgram(patient) {
    if (patient.archived_at) {
      return false;
    }

    const p = patient.program || patient.formal_program_name.toLowerCase();

    const in_ghl_program = p.indexOf("ghl") !== -1;
    const in_blank_ghl_program = p.indexOf("blank") !== -1;

    return in_ghl_program && !in_blank_ghl_program;
  }

  ensurePhone() {
    const { user } = this.props;
    const phone = user.about.phone;
    if (!phone) {
      const alertMessage =
        "Phone number must be set when modifying patients program";
      this.setState({ alertMessage });
      this.state.alertMessage = alertMessage;
      return false;
    }

    return true;
  }

  shouldDisplayResetButton(user) {
    const { userType } = this.props;
    const isInvite = userType === "invite";

    if (isInvite || !this.isGhlProgramName(user)) {
      return false;
    }

    if (inResettableProgram(user)) {
      return true;
    }

    return !!user.archived_at;
  }

  renderRegistrationInfo() {
    const {
      user,
      userType,
      externalService,
      onEditAssignedTo,
      onTransitionPatient,
    } = this.props;

    const inviter =
      user.inviter &&
      `${user.inviter.email} (${inflection.humanize(
        user.inviter.userable_type
      )})`;
    // assignedTo in the API is called nurse! So....
    const assignedTo = (user.nurse && user.nurse.email) || "-";
    const isInvite = userType === "invite";
    const formattedInvitedAt =
      user.invited_at && moment(user.invited_at).format(dateFormat);
    const formattedRegisteredAt =
      user.registered_at && moment(user.registered_at).format(dateFormat);
    const isWorkersComp = externalService && externalService === "workerscomp";

    return (
      <div>
        {
          <div className="user-actions">
            <btn
              className="btn btn-sm"
              onClick={() =>
                this.ensurePhone() &&
                this.setState({ changeProgramAlertVisible: true })
              }
            >
              {isInvite ? "Start Program" : "Change Program"}
            </btn>

            {this.shouldDisplayResetButton(user) && (
              <btn
                className="btn btn-sm"
                onClick={() =>
                  this.ensurePhone() &&
                  this.setState({ resetProgramAlertVisible: true })
                }
              >
                {inResettableProgram(user) ? "Reset Program" : "Resume Program"}
              </btn>
            )}
          </div>
        }

        <Confirm
          callback={(confirmed) => {
            this.setState({ changeProgramAlertVisible: false });
            if (confirmed) {
              onTransitionPatient();
            }
          }}
          message="Changing patient's program"
          subText="When you change a patients program it resets back to day 1. Is this OK?"
          visible={this.state.changeProgramAlertVisible}
        />

        <Confirm
          callback={async (confirmed) => {
            this.setState({ resetProgramAlertVisible: false });
            if (confirmed) {
              await resetProgram(user.id);
              document.location.reload();
            }
          }}
          message={`${
            inResettableProgram(user) ? "Reset" : "Resume"
          } patients program`}
          subText={`Are you sure you want to ${
            inResettableProgram(user) ? "reset" : "resume"
          } this patients program ${
            inResettableProgram(user) ? "to day 1" : ""
          }?`}
          visible={this.state.resetProgramAlertVisible}
        />
        <Set
          className="program"
          label="Program"
          value={user.formal_program_name}
        />
        <Set
          classname="invited"
          label="Invited on"
          value={formattedInvitedAt}
        />
        <Set
          classname="registered"
          label="Registered on"
          value={formattedRegisteredAt}
        />
        <Divider style={dividerStyle} />
        {!isWorkersComp && (
          <div>
            <Set className="invited_by" label="Invited by" value={inviter} />
            <Set
              className="assigned-to"
              label="Assigned to"
              value={assignedTo}
              onEditButton={!isInvite && onEditAssignedTo}
            />
          </div>
        )}
      </div>
    );
  }

  isGhlProgramName(patient) {
    const p = patient.program || patient.formal_program_name.toLowerCase();
    return p.indexOf("ghl") !== -1;
  }

  renderArchive(user) {
    const { userType } = this.props;
    const isInvite = userType === "invite";

    return (
      <div className="archive-container">
        <Archive patient={user} isInvite={isInvite} /> <p>Archived</p>
      </div>
    );
  }

  render() {
    const { user, userType, authenticatedUserType, onEditName } = this.props;

    const showDeviceInfo = ["admin", "partner_admin", "owner"].includes(
      authenticatedUserType
    );

    const shouldHideForCareTeamUser =
      authenticatedUserType === "care_team_user";

    return (
      <div className={classNames("user-basic", `user-basic-${userType}`)}>
        <h1 onDoubleClick={this.toggleDebug}>
          {user.about.name}{" "}
          {shouldHideForCareTeamUser ? null : (
            <HoverImage
              onClick={onEditName}
              src={editIcon}
              hoverSrc={editIconHover}
            />
          )}
        </h1>
        {this.renderDebugIfNeeded()}
        {this.renderExternalServices()}
        <Divider style={dividerStyle} />
        {!shouldHideForCareTeamUser && this.renderAdministrativeInfo()}
        {this.renderContactInfo()}
        {this.renderInsurerInfo()}
        {this.renderMedicalInfo()}
        {showDeviceInfo && this.renderDeviceInfo()}
        {!shouldHideForCareTeamUser && this.renderRegistrationInfo()}
        <Divider style={dividerStyle} />
        {this.renderArchive(user)}
        <Divider style={dividerStyle} />
      </div>
    );
  }
}

Basic.propTypes = {
  user: PropTypes.object.isRequired,
  userType: PropTypes.oneOf(["peer", "invite"]).isRequired,
  authenticatedUserType: PropTypes.string.isRequired,
  externalService: PropTypes.string.isRequired,
  onEditName: PropTypes.func.isRequired,
  deviceInfo: PropTypes.object,
  onAddCareTeamMember: PropTypes.func.isRequired,
  onEditCareTeamMember: PropTypes.func.isRequired,
  onRemoveCareTeamMember: PropTypes.func.isRequired,
  onRelease: PropTypes.func.isRequired,
  onEditOwner: PropTypes.func.isRequired,
  onEditEmail: PropTypes.func.isRequired,
  onEditProcedure: PropTypes.func.isRequired,
  onEditSurgeryDate: PropTypes.func.isRequired,
  onEditSurgeryComplications: PropTypes.func.isRequired,
  onEditAssignedTo: PropTypes.func.isRequired,
  onEditPhone: PropTypes.func.isRequired,
  onEditBirthDate: PropTypes.func.isRequired,
};

export default Basic;
