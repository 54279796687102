import React from "react";
import PropTypes from "prop-types";

import ModalPanel from "./../../../../../../shared/components/ModalPanel";

import UpdateSurgeryDate from "./components/UpdateSurgeryDate";
import UpdateEmail from "./components/UpdateEmail";
import UpdateAssignedTo from "./components/UpdateAssignedTo";
import UpdatePhone from "./components/UpdatePhone";
import UpdateName from "./components/UpdateName";

import UpdateBackground from "./../../../../components/UserView/components/Forms/UpdateBackground";
import DeletePatient from "../../../../Peer/View/components/Actions/components/DeletePatient";
import UserSpecific from "../../../../../../shared/components/UserSpecific";
import AddCareTeamMember from "../../../../Peer/View/components/Actions/components/AddCareTeamMember";
import UpdateOwner from "../../../../Peer/View/components/Actions/components/UpdateOwner";

import "./Actions.css";
import UpdateUserLanguage from "./components/UpdateLanguage";
import TransitionProgram from "../../../../Peer/View/components/Actions/components/TransitionProgram/TransitionProgram";

function Actions({ onDeletePatient, user, authenticatedUserType }) {
  return (
    <div className="user-actions">
      <ModalPanel name="transitionProgram">
        <TransitionProgram
          userId={user.id}
          customerId={user.customer && user.customer.id}
        />
      </ModalPanel>
      <ModalPanel name="updateLanguage">
        <UpdateUserLanguage
          userId={user.id}
          currentLanguage={user.language}
          type="invite"
        />
      </ModalPanel>
      <ModalPanel contentHeight="400px" name="updateSurgeryDate">
        <UpdateSurgeryDate userId={user.id} />
      </ModalPanel>
      <ModalPanel name="updateEmail">
        <UpdateEmail userId={user.id} />
      </ModalPanel>
      <ModalPanel name="updateAssignedTo">
        <UpdateAssignedTo userId={user.id} />
      </ModalPanel>
      <ModalPanel topMargin="20px" name="updateBackground">
        <UpdateBackground userId={user.id} intakeData={user.intake_data} />
      </ModalPanel>
      <ModalPanel name="updatePhone">
        <UpdatePhone userId={user.id} phone={user.phone} />
      </ModalPanel>
      <ModalPanel name="updateName">
        <UpdateName name={user.about.name} userId={user.id} />
      </ModalPanel>
      {user.customer && (
        <ModalPanel name="updateOwner" width="310px">
          <UpdateOwner
            authenticatedUserType={authenticatedUserType}
            userId={user.id}
            customerId={user.customer.id}
          />
        </ModalPanel>
      )}
      <ModalPanel name="addCareTeamMember" width="310px">
        <AddCareTeamMember userId={user.id} />
      </ModalPanel>
      <UserSpecific type={["admin", "owner", "partner_admin"]}>
        <btn className="btn btn-sm btn-danger" onClick={onDeletePatient}>
          Delete Invite
        </btn>
      </UserSpecific>
      <ModalPanel withCloseButton={false} name="deletePatient">
        <DeletePatient patientId={user.id} patientType="invite" />
      </ModalPanel>
    </div>
  );
}

Actions.propTypes = {
  user: PropTypes.object.isRequired,
  authenticatedUserType: PropTypes.string.isRequired,
  onDeletePatient: PropTypes.func.isRequired,
  selectedId: PropTypes.string.isRequired,
};

export default Actions;
