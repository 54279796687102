import envConfig from "./env.generated.json";

const prodConfig = {
  PREVENTION_API_HOST: "https://prevention-api.peerwell.co",
  GOOGLE_ANALYTICS_ID: "UA-55724740-8",
  APP_URL: "", // not sure if this needs to be set
};

const config = { ...prodConfig, ...envConfig };

export default config;
